import $ from 'jquery'
import '../styles/main.scss'

// js scroll to links
$(".js-scroll-to").on('click', function(e) {

	e.preventDefault();

	$('html, body').animate({
		scrollTop: $( this.hash ).offset().top
	}, 500);

});
